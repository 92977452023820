<template>
  <ion-grid class="full-height full-width">
    <ion-row class="bottom-divider ion-align-items-center pad-ten">
      <ion-col size="auto">
        <ion-menu-toggle>
          <button class="prime-button button-primary"><ion-icon name="menu"></ion-icon></button>
        </ion-menu-toggle>
      </ion-col>
      <ion-col v-if="$route.name !== 'orgsDashboard'" size="auto" align-self-center size-xs="auto">
        <a
          class="cursor-pointer link-grey text-bold valign desktop"
          v-if="backText && backToNamedRoute"
          @click="goToNamedRoute(backToNamedRoute)"
        >
          <ion-icon name="ios-arrow-back" class="icon-back"></ion-icon>
          {{ backText }}
        </a>
        <a
          class="cursor-pointer link-grey text-bold valign mobile"
          @click="goToPrev()"
          v-if="this.$store.state.panelFlow.prevPanel !== ''"
        >
          <ion-icon name="ios-arrow-back" class="icon-back"></ion-icon>
          Back
        </a>
      </ion-col>
      <ion-col></ion-col>
      <ion-col
        v-if="($route.name !== 'orgsDashboard' && currOrg != null) || !isSecurityData"
        size-xs="auto"
        size-sm="auto"
        size-md="4"
      >
        <span
          v-if="currentProfessional"
          @click="showModal(searchPatientsModal)"
          class="form-control-search search-secondary valign mobile cursor-pointer"
        >
          <IconSearch class="icon-small icon-primary" id="search-icon" />
        </span>
        <span id="search" class="form-control-search search-secondary valign desktop">
          <input
            type="search"
            class="full-width"
            v-model="searchString"
            placeholder="Search for patient..."
            @input="preDebounce($event)"
            @keyup.enter="searchInput()"
          />
          <ion-spinner
            v-if="showSpinner"
            name="lines"
            class="spinner-medium valign margin-right-ten"
          ></ion-spinner>
          <IconSearch v-else-if="!searchString" class="icon-small icon-primary" id="search-icon" />
          <button
            v-else
            @click="clearSearch()"
            class="button prime-button button-transparent"
            id="clear-btn"
          >
            <ion-icon
              id="clear-icon"
              name="md-close"
              class="icon-small icon-primary margin-right-five"
            ></ion-icon>
          </button>
        </span>
        <ion-content
          v-click-outside="hidePopup"
          class="prime-popover"
          v-bind:style="{
            left: searchBoxPosition - 13 + 'px',
            height: emptyResults ? '100px' : '400px'
          }"
          v-if="showPopover"
        >
          <div
            v-if="emptyResults"
            class="no-results text-muted text-small text-center center-center"
          >
            There are no results to display
          </div>
          <ion-list v-else class="pad-ten">
            <ion-item
              lines="none"
              v-for="(patient, key) in searchResults"
              :key="key"
              class="prime-patient-card ion-margin-bottom margin-lr-ten"
            >
              <div
                @click="goToPatientGeneralSettings(patient.beneficiaryId)"
                :class="{ 'cursor-pointer': patient.displayStatus == 'Enrolled' }"
                style="margin-left: -10px;"
              >
                <common-patient-banner
                  :patient.prop="generatePatientSearchData(patient)"
                  :searchPatient.prop="generateSearchKeywordData()"
                />
              </div>

              <div class="prime-patient-card-icons">
                <button
                  v-if="patient.displayStatus == 'Enrolled'"
                  @click="makeSelection(patient)"
                  class="prime-button button-primary button-solo"
                  v-tooltip="'Message Patient'"
                >
                  <IconChatBubble class="prime-main-menu-icon" />
                </button>
              </div>
            </ion-item>
          </ion-list>
          <ion-infinite-scroll
            @ionInfinite="lazyLoad($event)"
            threshold="10px"
            id="infinite-scroll"
            :disabled="endOfSearch"
          >
            <ion-infinite-scroll-content
              loading-spinner="lines"
              loading-text="Loading more results..."
            >
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </ion-content>
        <!-- {{ searchResults }} -->
      </ion-col>
      <ion-col
        v-if="
          $route.name !== 'orgsDashboard' &&
            currOrg != null &&
            Object.keys(isSecurityData).length > 1
        "
        class="pad-left-ten"
        size="auto"
      >
        <span
          v-if="currentProfessional"
          @click="showModal(popoverOrganizationSelect)"
          class="form-control-search search-secondary valign mobile cursor-pointer"
        >
          <IconGlobe class="icon-small icon-primary" />
        </span>
        <span class="valign desktop">
          <OrganizationSelect />
        </span>
      </ion-col>
      <ion-col
        v-if="$route.name !== 'orgsDashboard' && currOrg == null && isSecurityData"
        class="pad-left-ten"
        size="auto"
      >
        <div>
          <button
            @click="goToNamedRoute('orgsDashboard')"
            class="prime-button button-secondary button-block"
          >
            Go To Organizations Dashboard
          </button>
        </div>
      </ion-col>
      <ion-col
        v-if="($route.name !== 'orgsDashboard' && currOrg != null) || !isSecurityData"
        size="auto"
        class="pad-lr-ten"
      >
        <div v-if="$can(I.ACCESS_EVENT_TRIGGER)">
          <button
            @click="goToNamedRoute('events')"
            class="prime-button button-secondary button-block"
            align-self-center
          >
            Enter Event
          </button>
        </div>
        <div
          v-else
          v-tooltip.top-start="{ content: $orgMissingPermissions('The Entering Events feature') }"
        >
          <button class="prime-button disabled button-block">Enter Event</button>
        </div>
      </ion-col>
      <ion-col size="auto">
        <LoggedinPerson class="margin-lr-ten" />
      </ion-col>
    </ion-row>
    <ion-row class="prime-row-container">
      <ion-col>
        <div
          v-if="loading"
          class="centerItems text-muted display-flex valign full-height full-width"
        >
          <ion-spinner name="lines" class="spinner-large valign"></ion-spinner>
        </div>
        <ion-content>
          <div v-if="!$isMobile" class="desktop inner-container full-height">
            <h3
              class="title margin-left-twenty"
              :class="{
                'text-center': titleAlignment === 'center'
              }"
              v-if="title"
            >
              {{ title }}
            </h3>
            <h5 class="subtitle" v-if="subtitle">{{ subtitle }}</h5>
            <slot />
          </div>

          <div v-if="$isMobile" class="mobile inner-container full-height">
            <slot />
          </div>
        </ion-content>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import IconChatBubble from "@/components/Global/Icons/IconChatBubble";
import IconChatBubbleDisabled from "@/components/Global/Icons/IconChatBubbleDisabled";
import LoggedinPerson from "@/components/Global/LoggedinPerson";
import ModalSearchPatients from "@/components/Global/ModalSearchPatients.vue";
import OrganizationSelect from "@/components/Global/OrganizationSelect";
import PopoverOrganizationSelect from "@/components/Global/PopoverOrganizationSelect";
import PopoverPatientSearchResults from "@/components/Global/PopoverPatientSearchResults.vue";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events.js";
import { default as auth, default as authState } from "@/utils/Auth";
import { addIcons } from "ionicons";
import { close, menu } from "ionicons/icons";
import _ from "lodash";
import IconGlobe from "../Global/Icons/IconGlobe.vue";
import IconSearch from "../Global/Icons/IconSearch.vue";

addIcons({
  "md-close": close.md,
  "md-menu": menu.md
});

export default {
  name: "PrimePage",
  components: {
    LoggedinPerson,
    OrganizationSelect,
    IconSearch,
    IconGlobe,
    IconChatBubbleDisabled,
    IconChatBubble
  },
  props: {
    title: String,
    subtitle: String,
    titleAlignment: String,
    loading: Boolean,
    backToNamedRoute: String,
    backText: String
  },
  data() {
    return {
      searchString: "",
      searchResults: [],
      searchBoxPosition: 0,
      showSpinner: false,
      searchPage: 1,
      endOfSearch: false,
      perPage: 8,
      emptyResults: false,
      showPopover: false,
      isSecurityData: auth.getSecurityData(),
      currOrg: authState.getOrgId(),
      popoverOrganizationSelect: PopoverOrganizationSelect,
      searchPatientsModal: ModalSearchPatients
    };
  },
  computed: {
    generateSearchKeywordData() {
      return () => ({
        isSearchEnabled: true,
        searchKeyword: this.searchString
      });
    },
    generatePatientSearchData() {
      return patient => ({
        resourceType: "Patient",
        name: [
          {
            family: patient.beneLastName,
            given: [patient.beneFirstName, patient.beneMiddleName],
            suffix: [patient.beneSuffix]
          }
        ],
        gender: "",
        birthDate: patient.dobStr,
        identifier: [
          {
            value: patient.mrn,
            type: {
              coding: [
                {
                  code: "MR"
                }
              ]
            }
          }
        ]
      });
    },
    cPatient() {
      return this.$store.state.chatProfessional.currentPatient;
    },
    currentProfessional() {
      return this.$store.getters["currentProfessional/get"];
    }
  },
  methods: {
    goToPatientGeneralSettings(id) {
      this.$router.push(`/patient-settings/general-info/${id}`);
    },
    showModal(theModal) {
      this.$ionic.modalController
        .create({
          component: theModal,
          mode: "ios",
          cssClass: "prime-modal-fullscreen",
          componentProps: {
            propsData: {
              // component: "future home of dynamic mobile component prop"
            }
          }
        })
        .then(m => m.present());
    },
    goToPrev() {
      if (window.innerWidth < 768) {
        let prevPanelPage = "";
        if (this.$store.state.panelFlow.prevPanel === "") {
          prevPanelPage = "";
        }
        this.$store.commit("setPanelFlow", {
          prevPanel: prevPanelPage,
          currentPanel: this.$store.state.panelFlow.currentPanel
        });
        this.$mobilize();
      }
    },
    lazyLoad(ev) {
      if (!this.endOfSearch) {
        this.searchCall("combine", ev);
      }
    },
    hidePopup() {
      this.showPopover = false;
    },
    makeSelection(patient) {
      if (patient.displayStatus != "Enrolled") {
        return;
      }
      this.hidePopup();
      this.clearSearch();

      // jump to conversations page if not already there

      if (this.$route.name !== "patientView") {
        this.$router.push({
          name: "patientView",
          query: { patient: patient.beneficiaryId }
        });
      } else {
        history.pushState(null, null, this.$route.path + "?patient=" + patient.beneficiaryId);
        EventBus.$emit("selectedPatientId", patient.beneficiaryId);
        this.$route.query.patient = patient.beneficiaryId;
      }
    },
    goToNamedRoute(routeName) {
      if (this.$route.query.backId) {
        this.$router.push({
          name: routeName,
          params: { id: this.$route.query.backId }
        });
      } else {
        this.$router.push({
          name: routeName
        });
      }
    },
    preDebounce(evt) {
      this.showPopover = false;
      if (evt.target.value.length <= 3) {
        this.showSpinner = false;
        this.searchResults = [];
      } else {
        this.debounceThis(evt);
      }
    },
    debounceThis: _.debounce(function(evt) {
      this.searchBoxPosition = evt.target.offsetLeft;
      this.searchInput(evt);
      this.searchResults = [];
    }, 800),
    async searchInput(evt) {
      // keeps selected message panel visible after searching
      this.showPopover = false;
      this.searchResults = [];
      this.searchPage = 1;
      this.endOfSearch = false;
      if (this.searchString.length > 2) {
        this.searchCall(evt);
      } else {
        this.showSpinner = false;
      }
    },
    async searchCall(responseStyle, evt) {
      this.showSpinner = true;
      const method = "post";
      const claims = await this.$authState.claims();
      const path = document.config.patientsApi + claims.professionalId;
      const searchText = this.searchString.trim();

      var payload = {
        page: this.searchPage,
        perPage: this.perPage,
        columnFilters: {
          beneLastName: searchText,
          beneFirstName: searchText,
          mrn: searchText,
          dob: searchText,
          orgId: this.$authState.getOrgId() ? this.$authState.getOrgId() : claims.orgIds
        },
        sort: [{ field: "beneLastName", type: "asc" }]
      };

      httpSend({ path, method, authToken: true, payload })
        .then(result => {
          if (responseStyle == "combine") {
            this.searchResults = [...this.searchResults, ...result.data.results];
          } else {
            this.searchResults = result.data.results;
          }
          result.data.results.length < this.perPage ? (this.endOfSearch = true) : "";

          result.data.results.length == 0
            ? (this.emptyResults = true)
            : (this.emptyResults = false);

          this.showPopover = true;

          evt ? evt.target.complete() : "";
        })
        .catch(error => {
          khanSolo.log(error);
        })
        .finally(() => {
          this.searchPage += 1;
          this.showSpinner = false;
        });
    },
    showSearchPopover(ev, searchResults) {
      this.$ionic.popoverController
        .create({
          component: PopoverPatientSearchResults,
          event: ev,
          mode: "ios",
          showBackdrop: false,
          arrow: false,
          cssClass: "popover-width-lg shadow no-arrow",
          componentProps: {
            data: {
              patients: searchResults
            }
          }
        })
        .then(m => m.present());
    },
    clearSearch() {
      this.searchString = "";
      document.querySelector("#search input").focus();
    }
  }
};
</script>

<style scoped>
.inner-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media (max-width: 767px) {
  .inner-container {
    height: 100vh;
  }
}

.prime-row-container {
  padding-bottom: 64px;
  height: 100%;
}

.prime-row-container-flatten {
  padding-bottom: 0px;
  height: 0px;
}

.container {
  overflow-y: auto;
  height: calc(100% - 58px);
}

.title {
  margin-top: 30px;
}

.subtitle {
  margin-top: 40px;
  margin-left: 30px;
}

.form-control-search.search-secondary {
  margin: 0 auto;
  max-width: 400px;
  padding-right: 10px;
}

.form-control-search.search-secondary.mobile {
  margin: 0 auto;
  width: 42px;
  height: 42px;
  padding: 0px;
  margin: 0px;
}

.form-control-search.search-secondary.mobile svg {
  margin: auto;
}

.prime-popover {
  position: absolute;
  z-index: 10000;
  width: 500px;
  -webkit-box-shadow: 0px 0px 20px -9px #000000;
  box-shadow: 0px 0px 20px -9px #000000;
  border-radius: 8px;
  background-color: #fff;
  --offset-top: -5px !important;
  --offset-bottom: -5px !important;
}

.no-results {
  background-color: #fff;
}

.prime-popover.empty div {
  background-color: #fff;
}

.prime-popover ion-list {
  background-color: #fff;
}

/* .prime-popover {
  margin-top: 10px;
  border-radius: 8px;
  background-color: #fff;
  padding: 15px 15px 5px 15px;
  position: absolute;
  z-index: 10000;
} */

.prime-patient-card {
  background: #ffffff;
  color: var(--ion-text-color);
  display: flex;
  align-items: center;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 8px;
  padding: 7px 0;
}

.prime-patient-card:hover {
  box-shadow: 0 3px 15px 0px rgba(46, 61, 73, 0.3) !important;
}

.prime-patient-card .avatar {
  width: 45px;
  height: 45px;
  font-size: 18px;
  margin-right: 20px;
}

.prime-patient-card .prime-patient-card-name {
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prime-patient-card .prime-patient-card-info {
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
  color: var(--ion-color-medium-shade);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prime-patient-card .prime-patient-card-info strong:not(:first-child) {
  margin-left: 10px;
}

.prime-patient-card .prime-patient-card-icons {
  display: flex;
  position: absolute;
  right: 20px;
}

.prime-patient-card .prime-patient-card-icons img {
  width: 22px;
  margin: 0 0 0 10px;
}

.active-conversation-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  margin-bottom: 5px !important;
  z-index: 100;
}

.active-conversation-header .avatar {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  flex-grow: 0;
}

.active-conversation-header h5 {
  margin: 0;
  flex-grow: 1;
}

.active-conversation-header .active-conversation-righ-icons {
  flex-grow: 0;
  display: flex;
}

.active-conversation-header .active-conversation-righ-icons * {
  margin-left: 10px;
}

/* ion-content {
  --offset-bottom: -60px !important;
  --offset-top: 0px;
} */

@media (max-width: 767px) {
  .prime-row-container ion-content {
    display: block;
  }

  ion-content {
    --offset-top: -5px !important;
  }
}

button.prime-button.button-transparent {
  height: 28px;
}

.disabled {
  color: var(--ion-color-medium) !important;
}
</style>
