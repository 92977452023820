<template>
  <ul class="prime-list lines-bottom" :key="cKey">
    <tree-control v-for="treenode in tree" :key="treenode.name" :nodes="treenode.orgs" :hasChildren="treenode.countSubOrgs" :label="treenode.name" :id="treenode.id" />
  </ul>
</template>

<script>
import store from "@/store";
import { EventBus } from "@/services/Events";
import TreeControl from "./TreeControl";
export default {
  name: "TreeWrapper",
  props: ["tree"],
  components: { TreeControl },
  data() {
    return {
      cKey: 3,
      id: null
    };
  },

  mounted() {
    this.id = store.state.organizations.organizationId;

    EventBus.$on("setPopoverChildren", id => {
      this.cKey = id;
    });
  }
};
</script>
<style lang="scss">
ion-list div:last-child .item-inner {
  border: none !important;
}
.prime-list {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}
.prime-list li {
  padding: 8px 0;
}
.prime-list.lines-bottom > li {
  border-bottom: 1px solid #dadada;
}
.prime-list.lines-top > li {
  border-top: 1px solid #dadada;
}
.prime-list li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
</style>
