<template>
  <div class="pad-lr-ten">
    <div v-if="patients.length < 1" lines="none" class="text-muted text-center pad-twenty">
      No available care team members
    </div>
    <div v-else>
      <ion-card v-for="(patient, key) in patients" :key="key" class="prime-patient-card ion-margin-bottom">
        <div class="avatar" v-if="patient.beneFirstName && patient.beneLastName">
          {{ patient.beneFirstName.charAt(0) + patient.beneLastName.charAt(0) }}
        </div>
        <div>
          <div class="prime-patient-card-name">
            {{ patient.beneLastName + ", " + patient.beneFirstName }}
          </div>
          <div class="prime-patient-card-info"><strong>ID: </strong>{{ patient.mrn }} <strong>PCP: </strong>{{ patient.pcpTitle | formatDisplayName(patient.pcpFirstName, patient.pcpLastName, patient.suffix) }}</div>
        </div>
        <div class="prime-patient-card-icons">
          <IconChatBubbleDisabled v-if="patient.activeConversation" class="icon-blue icon-small" v-tooltip.right="'This patient is currently in an active conversation.<br>No new conversation can be started until the <br>current conversation is complete.'" />
          <IconMoon v-if="patient.doNotDisturb" class="icon-blue icon-small" v-tooltip.right="'Patient is currently in &quot;Do Not Disturb&quot;'" />
        </div>
      </ion-card>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/services/Events";

export default {
  name: "PopoverPatientSearchResults",
  data() {
    return {
      taskId: Number,
      name: "",
      patients: Array,
      assignToProfessionalName: undefined,
      filteredPatients: [],
      selectedIndex: -1,
      cTaskId: 0,
      searchSpot: null,
      searchedProfessional: ""
    };
  },
  beforeMount() {
    this.filteredPatients = this.patients;
    this.cTaskId = this.taskId;
  },
  methods: {
    highLight(cInput) {
      const regex = new RegExp(this.name, "i");
      let matchingString = cInput.match(regex);
      return matchingString ? cInput.replace(matchingString, `<strong>${matchingString}</strong>`) : cInput;
    },
    filterInput(e) {
      this.searchedProfessional = "";
      const regex = new RegExp(this.name, "gi");
      if (this.name !== "") {
        let tempFilterProfessionals = this.availableProfessionals.filter(entry => entry.firstName.match(regex) || entry.lastName.match(regex));
        if (this.filteredPatients.length !== tempFilterProfessionals.length) {
          this.selectedIndex = -1;
        }
        this.filteredPatients = tempFilterProfessionals;
      } else {
        this.filteredPatients = this.availableProfessionals;
      }
      if (e.key === "ArrowDown") {
        this.selectedIndex = this.filteredPatients.length - 1 === this.selectedIndex ? 0 : this.selectedIndex + 1;
        this.searchedProfessional = this.filteredPatients[this.selectedIndex].firstName + " " + this.filteredPatients[this.selectedIndex].lastName;
        this.searchSpot.placeholder = this.searchedProfessional;
      }
      if (e.key === "ArrowUp") {
        this.selectedIndex = this.selectedIndex > 0 ? this.selectedIndex - 1 : this.filteredPatients.length - 1;
        this.searchedProfessional = this.filteredPatients[this.selectedIndex].firstName + " " + this.filteredPatients[this.selectedIndex].lastName;
        this.searchSpot.placeholder = this.searchedProfessional;
      }

      if (e.key === "Enter") {
        this.name = this.searchedProfessional;
        this.confirmAssignTo(this.cTaskId, this.filteredPatients[this.selectedIndex]);
      }
    },
    hoverSelect(i) {
      this.selectedIndex = i;
      this.searchedProfessional = this.filteredPatients[this.selectedIndex].firstName + " " + this.filteredPatients[this.selectedIndex].lastName;
      this.searchSpot.placeholder = this.searchedProfessional;
    },
    confirmAssignTo(taskId, professional) {
      this.assignToProfessionalName = professional.firstName + " " + professional.lastName;
      this.$ionic.alertController
        .create({
          header: "Confirm Task Assignment",
          message: "Assign this task to " + this.assignToProfessionalName + "?",
          mode: "ios",
          buttons: [
            {
              text: "No",
              role: "cancel",
              cssClass: "secondary",
              handler: () => {}
            },
            {
              text: "Yes",
              handler: () => {
                this.updateAssignedTo(taskId, professional);
              }
            }
          ]
        })
        .then(a => a.present());
    },
    updateAssignedTo: async function(taskId, professional) {
      EventBus.$emit("taskAssigned", taskId, professional);
      this.$ionic.popoverController.dismiss();
    }
  }
};
</script>
<style scoped>
ion-list.list-ios {
  margin-bottom: 0;
}
ion-item {
  padding: 0 5px;
}
ion-item:hover {
  background: var(--ion-color-light);
}
ion-label {
  font-weight: 500;
}
ul.professional-list {
  list-style-type: none;
  padding: 0;
  max-height: 300px;
  overflow-y: scroll;
  margin-bottom: 0;
}

.professional-list li {
  padding: 10px 20px;
  font-size: 16px;
}

.professional-list li:hover {
  background: var(--ion-color-light);
  cursor: pointer;
}

.pcp-selected-item {
  background: var(--ion-color-light);
}

ion-card.prime-patient-card {
  background: #ffffff;
  color: var(--ion-text-color);
  display: flex;
  align-items: center;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
ion-card.prime-patient-card:hover {
  box-shadow: 0 3px 15px 0px rgba(46, 61, 73, 0.3) !important;
}
ion-card.prime-patient-card .avatar {
  width: 60px;
  height: 60px;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 20px;
}
ion-card.prime-patient-card .prime-patient-card-name {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card.prime-patient-card .prime-patient-card-info {
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
  color: var(--ion-color-medium-shade);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card.prime-patient-card .prime-patient-card-info strong:not(:first-child) {
  margin-left: 10px;
}
ion-card.prime-patient-card .prime-patient-card-icons {
  display: flex;
  position: absolute;
  right: 20px;
  top: 15px;
}
ion-card.prime-patient-card .prime-patient-card-icons img {
  width: 22px;
  margin: 0 0 0 10px;
}
</style>
