<template>
  <button @click="showPopover($event)" class="prime-button button-secondary button-block orgs-button">
    <ion-col>
      <span class="text-bold margin-left-five">Org:&nbsp;</span><span class="text-normal margin-right-ten">{{ organizationName }}</span></ion-col
    >
    <ion-col size="auto" class="desktop">
      <ion-icon name="ios-arrow-down"></ion-icon>
    </ion-col>
  </button>
</template>

<script>
import store from "@/store";
import { mapActions } from "vuex";
import { addIcons } from "ionicons";
import { arrowDown } from "ionicons/icons";
import PopoverOrganizationSelect from "@/components/Global/PopoverOrganizationSelect.vue";

addIcons({
  "ios-arrow-down": arrowDown.ios
});

export default {
  name: "OrganizationSelect",
  data() {
    return {
      orgName: ""
    };
  },

  computed: {
    organizationName() {
      return store.getters["organizations/getOrganizationName"];
    },

    organizations() {
      return store.getters["organizations/getOrganizations"];
    }

  },

  mounted() {
    const orgData = store.getters["organizations/getOrganizations"];

    if (!orgData.length) {
      this.fetchOrganizations();
    }
  },


  methods: {
    ...mapActions({
      fetchOrganizations: "organizations/AssignOrganization"
    }),

    async showPopover(evt) {
      if(this.organizations.length) {
        const popover = await this.$ionic.popoverController.create({
          component: PopoverOrganizationSelect,
          event: evt,
          mode: "ios",
          cssClass: "popover-width-sm",
        });

        await popover.present();
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logged-in-user {
  padding: 6px;
  display: flex;
  border-radius: var(--prime-card-border-radius);
  transition: background 0.2s ease-in-out;
}
.logged-in-user:hover {
  background: var(--color-secondary-button);
}

.logged-in-user-name {
  font-size: 12px;
  font-weight: 600;
  align-self: center;
  margin-right: 10px;
}
.down-arrow {
  width: 20px;
}
.orgs-button {
  min-width: 200px;
}
</style>
