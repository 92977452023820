<template>
  <div class="column-stretch-wrap pad-bottom-twenty">
    <div class="column-stretch-header pad-lr-ten pad-top-ten">
      <button @click="goToNamedRoute('orgsDashboard')" class="prime-button button-primary button-block">
        Go to Organizations Dashboard
      </button>
    </div>
    <div class="column-stretch-body pad-left-fifteen pad-top-ten">
      <tree-wrapper v-if="orgsList.length" :tree="orgsList" />
    </div>
    <div class="column-stretch-footer top-divider pad-top-twenty pad-lr-twenty mobile">
      <ion-row>
        <ion-col></ion-col>
        <ion-col size-xs="12" size-sm="8" size-md="6">
          <button @click="closeOrgs()" class="prime-button button-secondary button-block">
            Close
          </button>
        </ion-col>
        <ion-col></ion-col>
      </ion-row>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/services/Events";
import store from "@/store";
import TreeWrapper from "./TreeWrapper.vue";
import { addIcons } from "ionicons";
import { logOut, settings, bug } from "ionicons/icons";
import router from "@/router";

addIcons({
  "ios-logout": logOut.ios,
  "ios-settings": settings.ios,
  "ios-bug": bug.ios
});

export default {
  name: "PopoverOrganizationSelect",

  components: { TreeWrapper },

  data() {
    return {
      orgsList: [],
      expandedChildren: []
    };
  },

  beforeMount() {
    this.orgsList = store.state.organizations.organizations;
    this.expandedChildren = store.state.organizations.expandedChildren;
  },

  async mounted() {
    EventBus.$on("setPopoverChildren", (id, children) => {
      const objToMerge = {
        id,
        orgs: children
      };

      this.orgsList = this.recurse(this.orgsList, id, objToMerge);
    });
  },
  beforeDestroy() {
    store.commit("organizations/mutateOrganizations", this.orgsList);
  },
  methods: {
    goToNamedRoute(routeName) {
      router.push({
        name: routeName
      });
      this.closeOrgs();
    },

    recurse(arr, idToFind, objToMerge) {
      return arr.map(obj => {
        const mergeThis = obj.id === idToFind;
        const merged = !mergeThis ? obj : { ...obj, ...objToMerge };
        if (merged.orgs) {
          merged.orgs = this.recurse(merged.orgs, idToFind, objToMerge, mergeThis);
        }
        return merged;
      });
    },
    closeOrgs() {
      this.$ionic.modalController.dismiss();
      this.$ionic.popoverController.dismiss();
    }
  }
};
</script>
