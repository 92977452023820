<template>
  <li>
    <ion-row class="ion-align-items-center">
      <ion-col size="auto">
        <div class="prime-checkbox margin-right-ten" :class="{ checked: currentOrgId == id }" @click="selectOrg(id, label)">
          <div />
        </div>
      </ion-col>
      <ion-col size="auto" class="cursor-pointer" v-if="cnodes.length || hasChildren" @click="toggleChildren(id, cnodes.length)">
        <ion-icon v-if="cnodes.length && showChildren.includes(id)" class="icon-small" name="ios-arrow-down"></ion-icon>
        <ion-icon v-else class="icon-small" name="ios-arrow-forward"></ion-icon>
      </ion-col>
      <ion-col :class="cnodes.length || hasChildren ? 'cursor-pointer' : ''" @click="toggleChildren(id, cnodes.length)">
        <ion-label>
          <span class="text-semibold">{{ label }}</span>
        </ion-label>
      </ion-col>
    </ion-row>

    <ul :key="JSON.stringify(showChildren)" v-if="cnodes.length && showChildren.includes(id)" class="prime-list lines-top margin-left-twenty">
      <tree-control v-for="treenode in cnodes" :key="treenode.name" :nodes="treenode.orgs" :hasChildren="treenode.countSubOrgs" :label="treenode.name" :id="treenode.id" />
    </ul>
  </li>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import { mapActions } from "vuex";
import { EventBus } from "@/services/Events";
import store from "@/store";
export default {
  name: "TreeControl",
  props: {
    label: {
      type: String,
      default: ""
    },
    id: {
      type: Number,
      default: 0
    },
    nodes: {
      type: Array,
      default: () => []
    },
    hasChildren: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showChildren: [],
      cnodes: []
    };
  },
  mounted() {
    this.showChildren = store.state.organizations.expandedChildren;
    this.cnodes = this.nodes;
  },
  methods: {
    async toggleChildren(id, childrenAvailable) {
      if (childrenAvailable) {
        this.showChildren.includes(id) ? this.showChildren.splice(this.showChildren.indexOf(id), 1) : this.showChildren.push(id);
      } else {
        try {
          const path = `${document.config.organizationsList}/${id}`;
          const method = "get";

          this.cnodes = await httpSend({ path, method, authToken: true })
            .then(response => {
              return response.data;
            })
            .catch(error => {
              this.$ionic.toastController
                .create({
                  header: "Failed to load Broadcast Message History",
                  message: error,
                  duration: 7000,
                  position: "top"
                })
                .then(m => m.present());
            });
        } catch (e) {
          this.$ionic.toastController
            .create({
              header: "Failed to load Broadcast Message History",
              message: e,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
        }

        if (this.cnodes.length) {
          // Set cKey value on parent
          // EventBus.$emit("setPopoverChildren", id, this.cnodes);
          EventBus.$emit("setPopoverChildren", id, this.cnodes);

          // Assings the newly loaded sub organizations
          store.dispatch("organizations/AssignChildren", { id, children: this.cnodes });
          // this.assignChildren({id, children: this.cnodes});

          if (!this.showChildren.includes(id)) this.showChildren.push(id);

          // Set org Ids in state
          // store.commit("organizations/mutateChildOrganizations", this.showChildren);
          store.dispatch("organizations/AssignChildOrganizationIds", this.showChildren);
          // this.setChildOrgIds(this.showChildren);

          return;
        }
      }
    },
    selectOrg(id, name) {
      store.dispatch("organizations/AssignOrganizationId", { id, name });

      if (store.state.routerKey !== id) {
        store.commit("setRouterKey", id);
      }

      // making patient roster as default screen when switch between org
      window.location.assign(`${window.location.origin}/professional/roster`);
      window.localStorage.removeItem("currentPresetName");
      window.localStorage.removeItem("roster.columnState");
      window.localStorage.removeItem("roster.filterModel");
    },
    closeOrgs() {
      this.$ionic.modalController.dismiss();
      this.$ionic.popoverController.dismiss();
    },
    ...mapActions({
      assignChildren: "organizations/AssignChildren",
      setChildOrgIds: "organizations/AssignChildOrganizationIds",
      setOrganizationId: "organizations/AssignOrganizationId"
    })
  },
  computed: {
    currentOrgId() {
      return store.state.routerKey;
    }
  }
};
</script>
<style lang="scss">
/* Task Checkbox ---------------- */
.prime-checkbox {
  height: 24px;
  width: 24px;
  display: block;
  position: relative;
}
.prime-checkbox div {
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  cursor: pointer;
}
.prime-checkbox div::before,
.prime-checkbox div::after {
  content: "";
  display: block;
  transition: all 0.2s ease-in-out;
}
.prime-checkbox div::before {
  height: 20px;
  width: 20px;
  border: 2px solid var(--ion-color-medium-shade);
  border-radius: 20px;
  background: #ffffff;
  cursor: pointer;
}
.prime-checkbox div:hover::before {
  border: 2px solid var(--ion-color-primary);
}
.prime-checkbox div::after {
  height: 5px;
  width: 10px;
  border-left: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  transform: rotate(-45deg);
  position: absolute;
  position: absolute;
  top: 7px;
  left: 6px;
}
.prime-checkbox.checked div:before {
  background: var(--ion-color-primary);
  border: 2px solid var(--ion-color-primary);
}
</style>
